import React, { useState, useEffect, useRef } from 'react';
import { Moon, Sun, Home, User, Briefcase, Mail, Book, Code, Download } from 'lucide-react';

export default function Portfolio() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const sectionRefs = {
    home: useRef(null),
    about: useRef(null),
    experience: useRef(null),
    skills: useRef(null),
    education: useRef(null),
    contact: useRef(null),
  };

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const scrollToSection = (sectionId) => {
    sectionRefs[sectionId].current.scrollIntoView({ behavior: 'smooth' });
  };

  const navItems = [
    { id: 'home', icon: Home, label: 'Home' },
    { id: 'about', icon: User, label: 'About' },
    { id: 'experience', icon: Briefcase, label: 'Work' },
    { id: 'skills', icon: Code, label: 'Skills' },
    { id: 'education', icon: Book, label: 'Edu' },
    { id: 'contact', icon: Mail, label: 'Contact' },
  ];

  return (
    <div className={`min-h-screen transition-colors duration-300 ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-gradient-to-br from-purple-900 via-indigo-900 to-pink-900 dark:from-black dark:via-purple-900 dark:to-indigo-900 min-h-screen text-white transition-colors duration-300">
        <nav className={`${isMobile ? 'fixed bottom-0 left-0 right-0' : 'fixed top-0 left-0 right-0'} bg-black bg-opacity-50 backdrop-blur-md z-50 transition-colors duration-300`}>
          <div className="container mx-auto px-4 py-2 flex justify-between items-center">
            {!isMobile && <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">Balasriharsha Cheeday</h1>}
            <ul className={`flex ${isMobile ? 'justify-around w-full' : 'space-x-4'}`}>
              {navItems.map((item) => (
                <li key={item.id}>
                  <button
                    onClick={() => scrollToSection(item.id)}
                    className={`flex flex-col items-center p-2 ${activeSection === item.id ? 'text-cyan-400' : 'text-white'} transition-all duration-300 hover:scale-110 hover:text-pink-400`}
                  >
                    <item.icon className="w-6 h-6" />
                    {isMobile && <span className="text-xs mt-1">{item.label}</span>}
                  </button>
                </li>
              ))}
              <li>
                <button onClick={toggleDarkMode} className="p-2 transition-all duration-300 hover:rotate-12 text-yellow-300 hover:text-yellow-400">
                  {isDarkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <main className={`container mx-auto px-4 ${isMobile ? 'pt-4 pb-20' : 'pt-20'}`}>
          <section ref={sectionRefs.home} id="home" className="min-h-screen flex items-center justify-center">
            <div className="text-center transform transition-all duration-500 hover:scale-105">
              <div className="mb-8 relative w-64 h-64 mx-auto">
                <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 rounded-full animate-pulse"></div>
                <img 
                  src="/main_image.png?height=256&width=256" 
                  alt="Balasriharsha Cheeday" 
                  className="rounded-full w-full h-full object-cover relative z-10 border-4 border-cyan-400 shadow-lg shadow-cyan-400/50"
                />
              </div>
              <h1 className="text-5xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-500">Balasriharsha Cheeday</h1>
              <p className="text-xl text-purple-300 mb-6">DevOps Engineer with 5+ years of experience</p>
              <a
                href="/BalaSriharsha_Resume.pdf"
                download
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 shadow-lg shadow-pink-500/50 transition-all duration-300 hover:scale-105"
              >
                <Download className="w-5 h-5 mr-2" />
                Download Resume
              </a>
            </div>
          </section>

          <section ref={sectionRefs.about} id="about" className="min-h-screen flex items-center justify-center">
            <div className="text-center bg-black bg-opacity-50 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105 border border-pink-500 shadow-pink-500/50">
              <h2 className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">About Me</h2>
              <p className="text-xl mb-4 text-cyan-300">Skilled DevOps Engineer with 5+ years of hands-on experience supporting, automating, and optimizing mission critical deployments in AWS, leveraging configuration management, CI/CD, and DevOps processes.</p>
              <ul className="list-none space-y-2 text-purple-300">
                <li className="flex items-center justify-center"><span className="mr-2 text-pink-500">📍</span> Bhimavaram, India</li>
                <li className="flex items-center justify-center"><span className="mr-2 text-pink-500">📞</span> 9491025667</li>
                <li className="flex items-center justify-center"><span className="mr-2 text-pink-500">📧</span> balasriharsha.ch@gmail.com</li>
                <li className="flex items-center justify-center">
                  <span className="mr-2 text-pink-500">🔗</span>
                  <a href="https://www.linkedin.com/in/balasriharsha77777/" target="_blank" rel="noopener noreferrer" className="underline hover:text-cyan-400 transition-colors duration-300">LinkedIn</a>
                </li>
              </ul>
            </div>
          </section>

          <section ref={sectionRefs.experience} id="experience" className="min-h-screen py-20">
            <h2 className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-500">Work Experience</h2>
            <div className="space-y-8">
              {[
                {
                  title: "DevOps Engineer",
                  company: "Openprise, Hyderabad",
                  period: "Aug 2023 - Present",
                  responsibilities: [
                    "Developed complete infra for creating and automating EKS Deployments with ArgoCD",
                    "Developed an Instance management system for cost saving, efficient resource management",
                    "Added complete fluentd and fluent-bit to push logs to logstash",
                    "Completely created all the aws infra using terraform",
                    "Regular Auto creation of an Infosec hardened AMI using Packer",
                    "Automated Disaster Recovery for SOC2 on RDS Databases using Lambda"
                  ]
                },
                {
                  title: "DevOps Engineer",
                  company: "Treez, Remote(WFH)",
                  period: "Jul 2022 - Aug 2023",
                  responsibilities: [
                    "Creating and Automating EKS Clusters",
                    "Working on Infosec to make the AWS Infra more secure",
                    "Making deployments easier with Ansible, Jenkins and Terraform"
                  ]
                },
                {
                  title: "DevOps Engineer",
                  company: "Constella Intelligence, Hyderabad",
                  period: "May 2019 - Jun 2022",
                  responsibilities: [
                    "Solid experience with Linux (Ubuntu, CentOS) administration",
                    "Experience with AWS - including but not limited to EC2, S3, CloudWatch, CloudFormation, OpsWorks, RDS, IAM, Redshift, ECS, ECR and Route53",
                    "Proficient with Git and Git Workflows",
                    "Experience with container Orchestration systems such as AWS ECS, EKS",
                    "Strong shell programming experience with Bash and expert at Python",
                    "Experience with server monitoring system Prometheus",
                    "Server automation with Terraform and Puppet",
                    "Experience with CI Systems - GitLab CI, Jenkins, Rundeck",
                    "Database Experience with MySQL, PostgreSQL"
                  ]
                },
                {
                  title: "DevOps Intern",
                  company: "Constella Intelligence, Vijayawada",
                  period: "Jul 2018 - Apr 2019",
                  responsibilities: [
                    "Auto-Documentation Updater"
                  ]
                }
              ].map((job, index) => (
                <div key={index} className="bg-black bg-opacity-50 rounded-lg p-6 transform transition-all duration-500 hover:scale-105 hover:rotate-1 border border-cyan-400 shadow-lg shadow-cyan-400/50">
                  <h3 className="text-2xl font-bold text-pink-400">{job.title}</h3>
                  <p className="text-xl mb-2 text-purple-300">{job.company}</p>
                  <p className="text-sm mb-4 text-cyan-300">{job.period}</p>
                  <ul className="list-disc pl-5 space-y-1 text-purple-200">
                    {job.responsibilities.map((resp, i) => (
                      <li key={i} className="text-sm">{resp}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section ref={sectionRefs.skills} id="skills" className="min-h-screen py-20">
            <h2 className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-500">Skills</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {[
                "AWS", "Docker", "Git", "Kubernetes", "Jenkins", "Terraform", "Python", "Ansible",
                "Shell Scripting", "ElasticSearch", "Databases(MySQL, PostgreSQL)", "CI/CD(Jenkins, Rundeck, GitLab)",
                "Web Design and Development", "Machine Learning", "App Development with Flutter", "ArgoCD"
              ].map((skill, index) => (
                <div key={index} className="bg-black bg-opacity-50 rounded-lg p-4 text-center transform transition-all duration-500 hover:scale-110 hover:rotate-3 border border-pink-500 shadow-lg shadow-pink-500/50">
                  <span className="text-cyan-300">{skill}</span>
                </div>
              ))}
            </div>
          </section>

          <section ref={sectionRefs.education} id="education" className="min-h-screen py-20">
            <h2 className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-500">Education</h2>
            <div className="space-y-8">
              {[
                {
                  degree: "M.Tech",
                  institution: "BITS Pilani, Distance Education",
                  period: "May 2023 - Present",
                  details: "Doing my MTech on AIML. So far I never failed a single subject. I also gained knowledge on how to use MLOps, and developing and deploying various ML applications on AWS"
                },
                {
                  degree: "Bachelor of Technology",
                  institution: "SRKR Engineering College, Bhimavaram",
                  period: "Jun 2015 - Jun 2019",
                  details: "I graduated from college with 7.0 CGPA. I have never failed a single subject in college"
                },
                {
                  degree: "Intermediate",
                  institution: "Narayana Junior College, Vijayawada",
                  period: "May 2013 - May 2015",
                  details: "I got 945 total out of 1000 in my Intermediate. My EAMCET Rank is 5434 with 97 marks. I got 142 score in JEE-MAINS"
                },
                {
                  degree: "10th Class",
                  institution: "Sri Kakatiya Merit School, Bhimavaram",
                  period: "Jun 2012 - Apr 2013",
                  details: "I got 9.5 GPA in my 10th Class"
                }
              ].map((edu, index) => (
                <div key={index} className="bg-black bg-opacity-50 rounded-lg p-6 transform transition-all duration-500 hover:scale-105 hover:-rotate-1 border border-purple-500 shadow-lg shadow-purple-500/50">
                  <h3 className="text-2xl font-bold text-pink-400">{edu.degree}</h3>
                  <p className="text-xl mb-2 text-purple-300">{edu.institution}</p>
                  <p className="text-sm mb-4 text-cyan-300">{edu.period}</p>
                  <p className="text-purple-200">{edu.details}</p>
                </div>
              ))}
            </div>
          </section>

          <section ref={sectionRefs.contact} id="contact" className="min-h-screen flex items-center justify-center">
            <div className="text-center bg-black bg-opacity-50 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105 border border-cyan-400 shadow-cyan-400/50">
              <h2 className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-500">Get in Touch</h2>
              <p className="text-xl mb-4 text-purple-300">Feel free to reach out for collaborations or just a friendly chat.</p>
              <div className="space-y-2 text-cyan-300">
                <p className="flex items-center justify-center"><span className="mr-2 text-pink-500">📞</span> 9491025667</p>
                <p className="flex items-center justify-center"><span className="mr-2 text-pink-500">📧</span> balasriharsha.ch@gmail.com</p>
                <p className="flex items-center justify-center">
                  <span className="mr-2 text-pink-500">🔗</span>
                  <a href="https://www.linkedin.com/in/balasriharsha77777/" target="_blank" rel="noopener noreferrer" className="underline hover:text-pink-400 transition-colors duration-300">LinkedIn</a>
                </p>
              </div>
            </div>
          </section>
        </main>

        <footer className="bg-black bg-opacity-50 text-center py-4 text-purple-300 transition-colors duration-300">
          <p>&copy; 2023 Balasriharsha Cheeday. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}